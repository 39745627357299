'use client'

import { Fragment, useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'
import NextError from 'next/error'
import { Dialog, Transition } from '@headlessui/react'

export default function ErrorPage({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error)
  }, [error])

  return (
    <div>
      <>
        <div className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
          <div className="text-lg font-medium leading-6 text-gray-900">
            We ran into a problem 😞
          </div>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              An error occurred while loading this page. We have been notified and
              will work on fixing it as soon as possible.
            </p>
          </div>

          <div className="mt-4">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent bg-yellow-100 px-4 py-2 text-sm font-medium text-yellow-900 hover:bg-yellow-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500 focus-visible:ring-offset-2"
              onClick={reset}
            >
              Retry
            </button>
          </div>
        </div>
      </>
    </div>
  )
}
